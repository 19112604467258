

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AllergySeverity } from '@shared/models/enums';
import ShowDetails from '@rs-911/components/ShowDetails.vue';
import FirstResponderInfoList from '@rs-911/components/FirstResponderInfoList.vue';
import QrMedinfoItem from '@rs-911/components/QrMedinfoItem.vue';
import { phoneFormat } from '@shared/services/validators';
import QRCode from 'qrcode';

@Component({
  components: {
    FirstResponderInfoList,
    ShowDetails,
    QrMedinfoItem
  }
})
export default class QrMedinfo extends Vue {
  phoneFormat = phoneFormat;
  medinfoData: any = '';
  allergies: any = [];
  diagnoses: any = [];
  physicians: any = [];
  medications: any = [];
  qrContacts: any = null;
  personalInfo: any = null;
  detailsData: any = '';
  qrCode = '';
  invalidSecret = false;
  invalidQrContacts = false;
  

  created() {
    QRCode.toDataURL(window.location.href)
    .then((url: string) => {
      this.qrCode = url;
    });
    this.$store.dispatch('medInfo/getInfoWithSecret', this.$route.params.secret).then(res => {
      this.medinfoData = res.data;
      this.personalInfo = {
        Title: 'Personal Info',
        Gender: res.data.personalInfo.gender,
        Age: res.data.personalInfo.age,
        DateOfBirth: new Date(res.data.personalInfo.dateOfBirth).toLocaleDateString(),
        selected: true
      };
      this.detailsData = this.personalInfo;
      this.allergies = res.data.allergies.map((x: { name: string; severity: number; epiPenRequired: boolean}) => {
        return {
          allergy: x.name,
          severity: this.getSeverity(x.severity),
          epiPen: x.epiPenRequired ? 'Yes' : 'No'
        };
      });
      this.diagnoses = res.data.diagnoses.map((x: { name: string; physicianFullName: string}) => {
        return {
          diagnosis: x.name,
          physician: x.physicianFullName
        };
      });
      this.physicians = res.data.physicians.map((x: { fullName: string; specialty: string; phoneNumber: string}) => {
        return {
          physician: x.fullName,
          specialty: x.specialty,
          phone: this.formatPhone(x.phoneNumber)
        };
      });
      this.medications = res.data.medications.map((x: { name: string; dosage: string; physicianFullName: string}) => {
        return {
          medication: x.name,
          dosage: x.dosage,
          physician: x.physicianFullName
        };
      });
      this.medinfoData = res.data;
    })
    .catch((err) => {
      this.medinfoData = false;
      this.invalidSecret = true;
    });

    this.$store.dispatch('contact/qrCode', this.$route.params.secret).then(res => {
      this.qrContacts = {
        personal: res.data.personal,
        contacts: res.data.contacts.map((x: { fullname: string; phone: string; relationship: string }) => {
          return {
            name: x.fullname,
            phone: this.formatPhone(x.phone),
            relationship: x.relationship
          };
        })
      };
    })
    .catch(err => {
      this.invalidQrContacts = true;
    });
  }

  getSeverity(num: number) {
    return AllergySeverity[num];
  }

  formatPhone(phone: string) {
    return phoneFormat(phone);
  }

  copyUrl() {
    const textToCopy = document.querySelector('#copy-link') as any;
    textToCopy.value = window.location.href;
    textToCopy.type = 'text';
    textToCopy.select();
    textToCopy.setSelectionRange(0, 99999);
    document.execCommand('copy');
    textToCopy.type = 'hidden';
    this.$store.dispatch('notify', {title: '', body: 'Link Copied to Clipboard', variant: 'success'});
  }
}
