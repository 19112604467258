









































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FirstResponderInfoList extends Vue {
  @Prop() private items: any;
  @Prop() private title!: string;
  @Prop() private type!: string;
  @Prop() private path!: string;

  getName(item: any) {
    if(item.allergy) return item.name;
    if(item.medication) return item.name;
    if(item.diagnose) return item.name;
    if(item.physician) return item.fullName;
  }

  updateDetails(item: any) {
    this.$emit('updateDetails', { item, type: this.type });
  }
}
