





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class QrMedinfoItem extends Vue {
  @Prop() private items!: [];
  @Prop() private title!: string;
  @Prop() private highlightKeys!: Array<string>;

  modifyKey(value: string) {
    const test =  value.replace(/([A-Z])/g, ' $1').trim();
    return test[0].toUpperCase() + test.slice(1);
  }

  highlightText(value: string) {
    return this.highlightKeys.some(x => x === value);
  }
}
