






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { phoneFormat } from '@shared/services/validators';

@Component
export default class ShowDetails extends Vue {
  @Prop() private data!: any;
  phoneFormat = phoneFormat;
  get dataWithoutTitle() {
    const { Title, selected, ...dataWithoutTitle } = this.data;
    return dataWithoutTitle;
  }

  modifyKey(value: string) {
    if(value === 'Text') return '';
    const test =  value.replace(/([A-Z])/g, ' $1').trim();
    return test[0].toUpperCase() + test.slice(1);
  }
}
